.login-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fc; /* Fondo claro para contraste */
}

.login-form {
  width: 100%;
  max-width: 500px; /* Ancho máximo aumentado para el formulario */
  background-color: #ffffff;
  padding: 2.5rem; /* Aumenta el padding para dar más espacio dentro del formulario */
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Sombra suave */
}

.table-container {
  width: 60%; /* Ajusta el ancho según prefieras */
  margin: 0 auto;
}
.table-container table {
  width: 100%;
  font-size: 0.9rem; /* Reduce el tamaño de la fuente */
  border-collapse: collapse; /* Bordes compactos */
}
.table-container th,
.table-container td {
  padding: 2px 5px; /* Reduce espacio interno: arriba/abajo y lados */
  line-height: 0; /* Reduce el espacio vertical dentro de las celdas */
  text-align: center; /* Opcional: centra el contenido */
}
.table-container th {
  font-weight: bold;
}
.table-container tr {
  height: auto; /* Deja que la altura de las filas dependa del contenido */
}

td input {
  border: none; /* Quita el borde del input */
  width: 100%; /* Hace que el input ocupe todo el ancho de la celda */
  background-color: transparent; /* Hace que el fondo sea transparente */
  padding: 0; /* Elimina el relleno interno del input */
  text-align: left; /* Alinea el texto a la izquierda */
}

table tr.subtotal-imponible td {
  background-color: salmon !important;
  font-weight: bold;
}

table tr.Costo-Neto td {
  background-color: yellow !important;
  font-weight: bold;
}

table tr.Costo-EPP td {
  background-color: lightblue !important;
  font-weight: bold;
}

table tr.Costo-Ingreso td {
  background-color: cyan !important;
  font-weight: bold;
}

/* Ajustar el ancho de las columnas */
.col-item {
  width: 50px; /* Ancho fijo pequeño */
  text-align: center; /* Centrar texto */
}

.col-cantidad {
  width: 80px; /* Ancho fijo moderado */
  text-align: center;
}

.col-unidad {
  width: 120px; /* Ancho adecuado para texto corto */
  text-align: left; /* Alinear texto a la izquierda */
}

.col-valor {
  width: 120px; /* Ancho fijo moderado */
  text-align: center;
}

.col-valorsiva {
  width: 120px; /* Ancho fijo moderado */
  text-align: center;
}

/* Ajustar tamaño para tabla completa */
.table {
  width: 100%;
  table-layout: auto; /* Permite que las columnas se ajusten al contenido */
}

/* Bordes y espaciado */
.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #ddd;
  padding: 8px;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* O el contenedor principal */
}

.centered-image {
  max-width: 40%;
  height: auto; /* Mantiene las proporciones */
}