.sidebar .sidebar-brand {
  width: 100%; /* Asegura que ocupe todo el ancho del contenedor */
  padding: 0; /* Elimina cualquier relleno */
  margin: 0; /* Elimina cualquier margen */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar .sidebar-brand img {
  width: 100%; /* La imagen ocupará todo el ancho disponible */
  height: auto; /* Mantiene la proporción */
  object-fit: contain; /* Asegura que la imagen se ajuste bien */
  margin: 0; /* Elimina márgenes de la imagen */
}

.sidebar .sidebar-brand {
  height: 10vh; /* Ajusta según el viewport */
}